<template>
  <section class="welcome welcome__zakyat">
    <div class="container">
      <div class="welcome__inner">
        <h1 class="welcome__title welcome__zakyat__title">
          КАЛЬКУЛЯТОР ЗАКЯТА
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WelcomeComponent'
}
</script>
