<template>
  <main class="main">
    <WelcomeComponent/>
    <Calc/>
    <Tabs/>
    <AboutRamadan/>
    <RemindModal/>
  </main>
</template>

<script>
import WelcomeComponent from '@/views/zakyat/components/Welcome.vue'
import Calc from '@/views/zakyat/components/Calc.vue'
import Tabs from '@/views/zakyat/components/Tabs.vue'
import AboutRamadan from '@/views/zakyat/components/AboutRamadan.vue'
import RemindModal from '@/views/zakyat/components/RemindModal.vue'

export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_CALC_PAGE')
    // await store.dispatch('GET_H0ME_PAGE')
  },
  name: 'zakyat',
  computed: {
    nav () {
      return this.$store.state.navigators.find(item => item.link === this.$route.path)
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      this.nav.meta_title,
      this.nav.meta_keywords,
      this.nav.meta_description,
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    WelcomeComponent,
    Calc,
    Tabs,
    AboutRamadan,
    RemindModal
  }
}

</script>
