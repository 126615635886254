<template>
  <i class="church__icon">
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.54338 3.3999C6.2864 3.3999 6.02905 3.54424 5.92138 3.83154L4.84338 6.70693V7.93324H8.24338V6.70693L7.16539 3.83154C7.05772 3.54424 6.80037 3.3999 6.54338 3.3999ZM28.0767 3.3999C27.8197 3.3999 27.5624 3.54424 27.4547 3.83154L26.3767 6.70693V7.93324H29.7767V6.70693L28.6987 3.83154C28.5911 3.54424 28.3337 3.3999 28.0767 3.3999ZM17.3101 6.23324C17.3101 6.23324 11.6434 9.06657 11.6434 12.4666C11.6434 14.0063 12.2623 15.1239 13.2593 15.8666H12.7767C11.5244 15.8666 10.5101 16.8809 10.5101 18.1332V29.4666H15.0434V26.0666C15.0434 23.5732 17.3101 21.987 17.3101 21.987C17.3101 21.987 19.5767 23.5732 19.5767 26.0666V29.4666H24.1101V18.1332C24.1101 16.8809 23.0957 15.8666 21.8434 15.8666H21.3608C22.3578 15.1239 22.9767 14.0063 22.9767 12.4666C22.9767 9.06657 17.3101 6.23324 17.3101 6.23324ZM4.84338 10.1999V29.4666H8.24338V10.1999H4.84338ZM26.3767 10.1999V29.4666H29.7767V10.1999H26.3767Z" fill="#44AD4E"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ChurchIcon'
}
</script>
