<template>
  <div v-if="$store.state.showRemindModal" class="modal__wrapper">
    <div @click="closeModal" class="modal__back">
    </div>
    <div class="modal__inner form__container">
      <form v-if="!formIsSent" @submit.prevent="submitRemindForm" class="modal__form">
        <div class="modal__inputs">
          <div class="modal__input">
            <label class="input__label" for="name">Email</label>
            <input @input="form.email.message = ''" v-model="form.email.value" :class="{ error: form.email.message}" class="input" id="name" type="email" placeholder="Введите почту" maxlength="255" required>
            <small v-if="form.email.message" class="form__error__text">{{ form.email.message }}</small>
          </div>
        </div>

        <button v-if="!loading" class="form__button" type="submit">Отправить</button>
        <button v-else class="form__button" type="button">Загрузка...</button>
      </form>
      <div v-else class="success">
        <p>Ваш запрос отправлен!</p>
      </div>

      <a @click="closeModal" class="modal__close__btn">
        <CloseBtn/>
      </a>
    </div>
  </div>
</template>

<script>
import CloseBtn from 'components/svg/Close.vue'

export default {
  name: 'CallMe',
  data () {
    return {
      form: {
        email: {
          value: '',
          message: ''
        }
      },
      loading: false,
      formIsSent: false,
      timeout: ''
    }
  },
  methods: {
    closeModal () {
      this.$store.state.showRemindModal = false
      this.formIsSent = false
      this.form.email.message = ''
      clearTimeout(this.timeout)
    },
    submitRemindForm () {
      this.form.email.message = !this.form.email.value ? 'Введите email' : ''

      if (!this.form.email.value) {
        return 0
      }

      this.loading = true

      this.$store.dispatch('POST_REMIND', {
        email: this.form.email.value
      })
        .then(response => {
          this.formIsSent = true
          this.loading = false
          Object.keys(this.form).forEach(item => {
            this.form[item].value = ''
          })
          this.timeout = setTimeout(() => {
            this.$store.state.showRemindModal = false
            this.formIsSent = false
            this.form.email.value = ''
          }, 2000)
        })
        .catch(err => {
          this.formIsSent = false
          this.loading = false
          console.log(err)
        })
    }
  },
  components: {
    CloseBtn
  }
}
</script>
