<template>
  <section class="section calc" id="calc" v-if="nisab">
    <div class="container">
      <div class="form__container">
        <div class="calc__inputs">
          <div v-for="(item, index) in inputs" :key="index" class="calc__input">
            <label :for="item.id" class="input__label">{{ item.title }}</label>
            <input @input="item.value = item.value.replace(/[^\d]/g, '')" :id="item.id" v-model="item.value"
                   class="input" type="text" placeholder="00.0">
            <span v-if="item.subtext">{{ item.subtext }}</span>
          </div>

          <div class="calc__input input__total">
            <label for="total" class="input__label">Общая сумма активов, облагаемых закятом</label>
            <input v-model="total" id="total" class="input" type="text" placeholder="00.0" readonly>

            <div class="calc__checkboxes">
              <div class="calc__checkbox">
                <label class="checkbox__label" for="debts">
                  <input v-model="debts.checked" id="debts" type="checkbox">
                  <span class="fake_checkbox"><CheckboxIcon/></span>
                  <span>Вычесть долги</span>
                </label>
                <input @input="debts.value = debts.value.replace(/[^\d]/g, '')" v-model.trim="debts.value" placeholder="00.0">
              </div>
              <div class="calc__checkbox">
                <label class="checkbox__label" for="costs">
                  <input v-model="costs.checked" id="costs" type="checkbox">
                  <span class="fake_checkbox"><CheckboxIcon/></span>
                  <span>Вычесть расходы</span>
                </label>
                <input @input="costs.value = costs.value.replace(/[^\d]/g, '')" v-model.trim="costs.value" placeholder="00.0">
              </div>
            </div>

          </div>
          <div class="calc__input input__nisab">
            <label for="nisab" class="input__label">Нисаб</label>
            <input :value="nisab.value + ' ₽'" id="nisab" class="input" type="text" placeholder="00.0" readonly>
          </div>
          <div class="calc__input input__zakyat">
            <label for="zakyat" class="input__label">Ваш закят</label>
            <input v-model="countZakyat" id="zakyat" class="input" type="text" placeholder="00.0" readonly>
            <span>*(0,025 х облагаемая закятом сумма)</span>
          </div>
        </div>

        <div v-show="this.total" class="calc__result">

          <div v-show="isFormVisible && this.total >= nisab.value" class="inputs">
            <div class="inputs__item input__name">
              <input @input="fioIsNotValid = false" v-model.trim="form.fio" type="text" :class="{ error: fioIsNotValid }" class="input input__name" placeholder="ФИО" maxlength="255">
              <span v-if="fioIsNotValid" class="form__error__text">Введите ФИО</span>
            </div>
            <div class="inputs__item input__phone">
              <input @input="phoneIsNotValid = false" v-model="form.phone"  type="text" :class="{ error: phoneIsNotValid }" class="input input__phone" v-mask="'+7 (###) ###-##-##'" placeholder="Номер телефона" maxlength="255">
              <span v-if="phoneIsNotValid" class="form__error__text">Введите номер телефона</span>
            </div>
          </div>

          <button @click="submitZakyatForm" v-show="isFormVisible && this.total >= nisab.value && !loader" type="button" class="calc__result__btn btn">
            Оплатить
          </button>
          <button @click="isFormVisible = true" v-show="!isFormVisible && this.total >= nisab.value" type="button" id="zakyatBtn" class="calc__result__btn btn">
            Выплатить закят
          </button>
          <button v-show="loader" type="button" class="calc__result__btn btn">
            <Loader/>
          </button>
          <div v-show="this.total && this.total < nisab.value" id="zakyatText" class="calc__result__text">
            Вы освобождены от выплаты Закята, так как ваше материальное состояние меньше нисаба.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CheckboxIcon from 'components/svg/CheckboxIcon.vue'
import Loader from 'components/Loader.vue'

export default {
  name: 'calc',
  data () {
    return {
      loader: false,
      isFormVisible: false,
      fioIsNotValid: false,
      phoneIsNotValid: false,
      form: {
        fio: '',
        phone: ''
      },
      debts: {
        checked: false,
        value: ''
      },
      costs: {
        checked: false,
        value: ''
      },
      inputs: {
        cash: {
          id: 'cash',
          title: 'Наличные деньги',
          subtext: '',
          value: ''
        },
        bank: {
          id: 'bank',
          title: 'Деньги на счетах в банке',
          subtext: '*(за вычетом начисленных процентов)',
          value: ''
        },
        goods: {
          id: 'goods',
          title: 'Товары',
          subtext: '*(по среднерыночной цене)',
          value: ''
        },
        gold: {
          id: 'gold',
          title: 'Золото и серебро',
          subtext: '*(используемое как инвестиционный актив)',
          value: ''
        },
        loaned: {
          id: 'lended',
          title: 'Отданное в долг',
          subtext: '',
          value: ''
        },
        other: {
          id: 'other',
          title: 'Другие доходы',
          subtext: '',
          value: ''
        }
      }
    }
  },
  computed: {
    nisab () {
      return this.$store.state.calc_page?.page_texts?.nisab
    },
    total () {
      let sum = 0
      Object.values(this.inputs).forEach(item => {
        if (item.value) {
          sum += Number(item.value)
        }
      })
      if (this.debts.checked && this.debts.value > 0) {
        sum -= this.debts.value
      }
      if (this.costs.checked && this.costs.value > 0) {
        sum -= this.costs.value
      }
      if (sum <= 0) {
        return ''
      }
      return sum
    },
    countZakyat () {
      if (this.total >= this.nisab.value) {
        return Math.round(parseFloat(this.total) * 10 * 0.025) / 10 + ' ₽'
      }
      return ''
    }
  },
  methods: {
    submitZakyatForm () {
      this.fioIsNotValid = !this.form.fio
      this.phoneIsNotValid = this.form.phone.length !== 18

      if (this.sumIsNotValid || this.fioIsNotValid || this.phoneIsNotValid) {
        return 0
      }

      this.loader = true
      this.$store.dispatch('POST_ZAKYAT_PAY', {
        sum: parseFloat(this.countZakyat),
        fio: this.form.fio,
        number: this.form.phone,
        cash: Number(this.inputs.cash.value),
        money_bank_accounts: Number(this.inputs.bank.value),
        products: Number(this.inputs.goods.value),
        gold_and_silver: Number(this.inputs.gold.value),
        loaned: Number(this.inputs.loaned.value),
        other_expenses: Number(this.inputs.other.value),
        total_assets: parseFloat(this.total),
        deduct_debts: this.debts.checked,
        deduct_expenses: this.costs.checked,
        debts: Number(this.debts.value),
        expenses: Number(this.costs.value)
      })
        .then(response => {
          this.loader = false
          if (response.data.url) {
            location.href = response.data.url
          }
          // очищение полей ввода
          // window.open(`${response.data.url}`, '_blank')
          // Object.keys(this.form).forEach(item => {
          //   this.form[item] = ''
          // })
          // Object.values(this.inputs).forEach(item => {
          //   item.value = ''
          // })
        })
        .catch(err => {
          this.loader = false
          console.log(err)
        })
    }
  },
  components: {
    CheckboxIcon,
    Loader
  }
}
</script>
