<template>
  <section class="section container" v-if="what_is || who_takes || sadaka">
    <h2 class="tabs__title">Основная информация</h2>
    <div class="form__container">

      <ul class="tabs__list">
        <li class="tabs__list__item" v-if="what_is.value">
          <a @click.prevent="setActive(0)" :class="{ active: activeTab === 0 }">Что такое</a>
        </li>
        <li class="tabs__list__item" v-if="who_takes.value">
          <a @click.prevent="setActive(1)" :class="{ active: activeTab === 1 }">Кто получает</a>
        </li>
        <li class="tabs__list__item" v-if="sadaka.value">
          <a @click.prevent="setActive(2)" :class="{ active: activeTab === 2 }">Садака</a>
        </li>
      </ul>
      <div class="tab__content">
        <div v-show="activeTab === 0" v-if="what_is.value">
          <h3 class="tab__title">{{ what_is.title }}</h3>
          <div class="tab__text">
            <p>{{ what_is.value }}</p>
          </div>
        </div>
        <div v-show="activeTab === 1" v-if="who_takes.value">
          <h3 class="tab__title">{{ who_takes.title }}</h3>
          <div class="tab__text">
            <p>{{ who_takes.value }}</p>
          </div>
        </div>
        <div v-show="activeTab === 2" v-if="sadaka.value">
          <h3 class="tab__title">{{ sadaka.title }}</h3>
          <div class="tab__text">
            <p>{{ sadaka.value }}</p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'Tabs',
  data () {
    return {
      activeTab: 0
    }
  },
  computed: {
    what_is () {
      return this.$store.state.calc_page?.page_texts?.what_is
    },
    who_takes () {
      return this.$store.state.calc_page?.page_texts?.who_takes
    },
    sadaka () {
      return this.$store.state.calc_page?.page_texts?.sadaka
    }
  },
  methods: {
    setActive (tab) {
      this.activeTab = tab
    }
  }
}
</script>
