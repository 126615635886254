<template>
  <section class="section container" v-if="calcTexts.about || dateRamadan">
    <div class="ramadan">

      <div class="ramadan__about" v-if="calcTexts.about">
        <h2 class="ramadan__about__title">{{ calcTexts.about.title }}</h2>
        <p>{{ calcTexts.about.value }}</p>
        <button v-if="!isTimeOver" @click="$store.state.showRemindModal = true" class="ramadan__btn" type="button">
          <ChurchIcon/>
          <span>Поставить себе напоминание</span>
        </button>
      </div>

      <button v-if="!isTimeOver" @click="$store.state.showRemindModal = true" class="ramadan__btn ramadan__btn__m" type="button">
        <ChurchIcon/>
        <span>Поставить себе напоминание</span>
      </button>

      <div class="ramadan__countdown" v-if="dateRamadan">
        <div class="ramadan__countdown__img">
          <img src="public/images/ramadan.jpg" alt="">
        </div>

        <div class="ramadan__countdown__info">
          <h3 v-if="!isTimeOver" class="ramadan__countdown__title">
            Осталось:
          </h3>
          <div v-if="!isTimeOver" class="ramadan__countdown__timer">
            <span class="countdown__days">{{ displayDays }} дней</span>
            <span>{{ displayHours }}:{{ displayMinutes }}:{{ displaySeconds }} часов</span>
          </div>

          <div v-else class="ramadan__countdown__over">
            Рамадан начался!
          </div>
        </div>
      </div>

      <h2 class="ramadan__about__title ramadan__about__title__m">{{ calcTexts.about.title }}</h2>
    </div>
  </section>
</template>

<script>
import ChurchIcon from 'components/svg/Church.vue'

export default {
  name: 'AboutRamadan',
  mounted () {
    this.countdown()
  },
  data () {
    return {
      isTimeOver: false,
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0
    }
  },
  computed: {
    calcTexts () {
      return this.$store.state.calc_page?.page_texts
    },
    dateRamadan () {
      return this.$store.state.calc_page?.date.date
    },
    msInSecond () {
      return 1000
    },
    msInMinute () {
      return this.msInSecond * 60
    },
    msInHour () {
      return this.msInMinute * 60
    },
    msInDay () {
      return this.msInHour * 24
    }
  },
  methods: {
    convertDateForIos (date) {
      const arr = date.split(/[- :]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    },
    countdown () {
      const timer = setInterval(() => {
        const now = new Date()
        const gap = this.convertDateForIos(this.dateRamadan).getTime() - now.getTime()

        if (gap <= 0) {
          clearInterval(timer)
          this.isTimeOver = true
          return 0
        }

        const days = Math.floor(gap / this.msInDay)
        const hours = Math.floor((gap % this.msInDay) / this.msInHour)
        const minutes = Math.floor((gap % this.msInHour) / this.msInMinute)
        const seconds = Math.floor((gap % this.msInMinute) / this.msInSecond)

        this.displayDays = days
        this.displayHours = hours < 10 ? '0' + hours : hours
        this.displayMinutes = minutes < 10 ? '0' + minutes : minutes
        this.displaySeconds = seconds < 10 ? '0' + seconds : seconds
      }, 1000)
    }
  },
  components: {
    ChurchIcon
  }
}
</script>
